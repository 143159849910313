var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",attrs:{"id":"template_card_selector"}},[_c('v-container',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',{staticClass:"text-left",attrs:{"left":""}},[_vm._v("New Care Card")])])],1),_c('v-row',{staticClass:"mt-16"},_vm._l((_vm.cardsData),function(card,n){return _c('v-col',{key:n,attrs:{"sm":"4","md":"3"}},[_c('v-card',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","border-radius":"10px"},attrs:{"to":{
             name: 'Edit',
             query: {
               source: 'template',
               type: 'care',
               chart_id: card.chart_id,
             },
           },"max-height":"150"}},[_c('v-img',{staticClass:"mt-5",attrs:{"height":"50","max-height":"50","max-width":"50","src":card.img}}),_c('v-card-text',{staticClass:"text-h8 mt-4 font-weight-bold text-center"},[_vm._v(" "+_vm._s(card.button)+" ")])],1)],1)}),1),_c('SmartSizeFooter')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }