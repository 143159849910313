<template>
  <div class="background" id="template_card_selector">
    <v-container>
      <v-row class="mt-5">
        <v-col cols="12" sm="6">
          <h1 left class="text-left">New Care Card</h1>
        </v-col>
      </v-row>
      <v-row class="mt-16">
        <v-col sm="4" md="3" v-for="(card, n) in cardsData" :key="n">
          <v-card
            :to="{
              name: 'Edit',
              query: {
                source: 'template',
                type: 'care',
                chart_id: card.chart_id,
              },
            }"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              border-radius: 10px;
            "
            max-height="150"
          >
            <!--       <v-card-title>{{ card.title }}</v-card-title>
 -->
            <v-img
              class="mt-5"
              height="50"
              max-height="50"
              max-width="50"
              :src="card.img"
            ></v-img>

            <v-card-text class="text-h8 mt-4 font-weight-bold text-center">
              {{ card.button }}
            </v-card-text>

            <!-- card is no clickalbe, commented text below no longer required -->

            <!--  <v-card-actions>
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{
                  name: 'Edit',
                  query: {
                    source: 'template',
                    type: 'care',
                    chart_id: card.chart_id,
                  },
                }"
              >
                <v-btn
                  color="#FFFFFF"
                  depressed
                  style="margin: 20px; padding: 0 20"
                >
                  {{ card.button }}
                </v-btn>
              </router-link>
            </v-card-actions> -->
            <!-- card is no clickalbe, commented text below no longer required -->
          </v-card>
        </v-col>
      </v-row>
      <SmartSizeFooter></SmartSizeFooter>
    </v-container>
  </div>
</template>
 
<script>
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  name: "template_card_selector",
  beforeMount() {
    this.fetchcharts();
  },
  data() {
    return {
      /* {
          img: require("@/assets/08-water30-a.png"),
          button: "Dark Icons",
          chart_id: 200,
        },
        {
          img: require("@/assets/08-water30-agrey.png"),
          button: "Grey Icons",
          chart_id: 201,
        },
        {
          img: require("@/assets/08-water30-apink.png"),
          button: "Pink Icons",
          chart_id: 202,
        },
        {
          img: require("@/assets/08-water30-ablue.png"),
          button: "Blue Icons",
          chart_id: 203,
        }, */
      cardsData: [],
    };
  },
  components: {
    SmartSizeFooter,
  },
  methods: {
    async fetchcharts() {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/template/all", {
          params: { type: "care" },
        })
        .then((response) => (this.data = response.data))
        .catch((error) => console.log(error));
      //console.log(this.data);
      this.cardsData = [];
      this.data.forEach((item) => {
        this.cardsData.push({
          img: require("@/assets/" + item.media),
          button: item.name,
          chart_id: item.id,
        });
      });
      localStorage.setItem("charts", JSON.stringify(this.data));
      /* this.data.forEach((fetchtab)=>{
        let tablist = []
        this.tabs.push(fetchtab.name);
        fetchtab.charts.forEach((item)=>{
          item = item.chart;
          tablist.push({
            img: require("@/assets/"+item.name+".svg"),
            button: item.name,
            chart_id: item.id,
          });
        });
        this.tabsItem.push(tablist);
      }); */
    },
  },
};
</script>
 
<style>
.background {
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}
</style>
 
